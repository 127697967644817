import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { Container, Row, Col, Button } from 'react-bootstrap';

import Layout from '../components/layout';
import Auth0 from '../utils/auth0';

const UserLogin = ({ accessToken }) => {
  useEffect(() => {
    if (accessToken) {
      navigate('/');
    }
  }, [accessToken]);

  return (
    <Layout>
      <Container className='mt-10 mb-5'>
        <Row>
          <Col>
            <h2>You are not logged in</h2>
          </Col>
        </Row>
      </Container>
      <div className='page-body'>
        <Container>
          <Row className='justify-content-center text-center'>
            <Col md={6}>
              <h3 className='mb-5'>Click here to login!</h3>
              <p className='mt-5'>
                <Button
                  variant='outline-primary'
                  className='font-weight-bold btn-lg btn-block'
                  onClick={async () => Auth0()}
                >
                  Login
                </Button>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.login.auth && state.login.auth.auth.accessToken,
});

export default connect(mapStateToProps)(UserLogin);
